export const apiList = {
  baseURL: "https://api.megalopolist.com",
  // baseURL: "http://192.168.29.38:3000",
  dashboard: {
    analytics: "/api/admin/get-total-dashboard",
    trend: "/api/admin/trend-visualization",
  },
  auth: {
    login: "/api/auth/login",
    forgot: "/api/auth/forgot-password",
    verifyOTP: "/api/auth/check-otp",
    updatePassword: "/api/auth/change-password",
    sendResetPasswordLink: "/api/auth/reset-password-link",
  },
  user: {
    getAll: "/api/user/get-all",
    getDetailById: "/api/user/get-details",
    action: "/api/user/account-status",
    updateprofile: "api/base/up/user/",
    analyticsTotal: "/api/user/get-total",
  },
  business: {
    getAll: "/api/business/get-all-business",
    getDetailById: "/api/business/get-bussiness-details",
    action: "/api/base/up/user",
    updateBusiness: "/api/business/update-business",
    updateHours: "/api/business/add-update-business-timings",
  },
  category: {
    getAll: "/api/categories/get",
    subGetById: "/api/base/get/",
    addNewCategory: "/api/categories/add",
    updateMainCat: "/api/categories/edit",
    deleteMainCat: "/api/categories/delete",
    addSubCat: "/api/categories/add",
    UpdateSubCat: "/api/categories/edit",
    deleteSubCat: "/api/categories/delete",
    addbulk: "/api/categories/add-cat-bulk",
  },
  subAdmin: {
    add: "/api/admin/add-subadmin",
    get: "/api/admin/admin-list",
    accessbyuserID: "/api/admin/get-modules",
    update: "user/account-status",
    add_update_access_right: "/api/admin/add-update-access-rights",
  },
  notification: {
    getAll: "/api/notification/get-notification",
    sentIndividual: "/api/notification/sent-notification",
    sentBulk: "/api/notification/sent-multiple-notification",
  },
  list: {
    getall: "/api/feed/get-all",
    delete: "/api/feed/delete-feed",
    detailsById: "/api/feed/get-feed-details",
    getAnalytics: "/api/feed/feed-analytics",
  },
  CountryStateCity: {
    getCountries: "/api/base/get?from=countries&arg=status = 1",
    getStates:
      "/api/base/get?from=states&arg=country_id = ? AND status = ?&prms=",
    getCities:
      "/api/base/get?from=cities&arg=state_id = ? AND status = ?&prms=",
  },
  rating: {
    getAll: "/api/rating/get-all",
    deleteById: "/api/rating/remove",
  },
  help: {
    getAll: "/api/help/get-all",
    action: "/api/help/checked",
  },
  content: {
    getPrivacy: "/api/content/get-privacy-policy",
    getTerms: "/api/content/get-terms-conditions",
    updatePrivacy: "/api/content/edit-privacy-policy",
    updateTerms: "/api/content/edit-terms-conditions",
  },
  report: {
    getAllUser: "/api/report/get-all-user",
    getAllFeed: "/api/report/get-all-feed",
    reporteduserdetails: "/api/report/reported-user-details",
    reportedfeeddetails: "/api/report/reported-feed-details",
  },
  chatModeration: {
    getAll: "/api/chat/chat-reported-list",
  },
  CommunityModeration: {
    getAll: "/api/report/community-report-list",
    getDetailsList: "/api/report/community-reported-details",
  },
  chatManagement: {
    getList: "/api/chat/chat-management-list",
    getMessages: "/api/chat/get-message",
  },
  chat: {
    socket: "/chat",
    sendMsg: "/api/chat/send-message",
    getMessages: "/api/chat/get-message",
    deleteMsg: "/api/chat/delete-message",
    getGroups: "/api/chat/all-group",
  },
  community: {
    getAllmsg: "/api/chat/get-chat-message",
    joinCommunity: "/api/chat/join-group",
  },
};
